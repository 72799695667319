import React, { useEffect, useState } from "react"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import Header from "../components/Header"
import querystring from "query-string"
import axios from "axios"

const getID = url => {
  const id = querystring.parse(url).id
  return id
}
export default function Details(props) {
  const ID = getID(props.location.search)
  const [details, setDetails] = useState({})
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    axios
      .get(`https://swapnanagar.herokuapp.com/activities/${ID}`)
      .then(res => {
        setDetails(res.data)
        console.log(res.data)
        setFetched(true)
      })
      .catch(error => {
        console.log(error)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      <SEO title="Activities" />
      <Header currentPage="activities" />
      {!fetched ? (
        <div class="d-flex justify-content-center m-top">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="container  m-top">
          <h1>
            <strong>{details.title}</strong>
          </h1>
          <h6>{details.activity_date}</h6>
          <br />
          <img
            src="https://www.pandasecurity.com/en/mediacenter/src/uploads/2013/11/pandasecurity-facebook-photo-privacy.jpg"
            alt="card"
            width="100%"
          />
          <br />
          <br />
          <br />
          <p>{details.details}</p>
        </div>
      )}
      <br />
      <br />
      {fetched ? <Footer /> : ""}
    </div>
  )
}
